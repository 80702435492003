import React from 'react';
import { Container, Content, Logo } from './styles';

export const Header = () => {
  return (
    <Container>
      <Content>
        <Logo src="/assets/img/logo.svg" alt="logo" />
      </Content>
    </Container>
  );
};
