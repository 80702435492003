import React from 'react';

import { GlobalStyle, ThemeProvider, themes } from '@dbs/react-ui-components';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from './styles';

const Layout: React.FC = ({ children }) => (
  <ThemeProvider theme={themes.light}>
    <GlobalStyle />
    <Container>
      <Header />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <Footer />
      </div>
    </Container>
  </ThemeProvider>
);

export default Layout;
